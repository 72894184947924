<template>
    <div>
        <div class="d-flex justify-space-between align-center pb-5">
            <p style="font-size: 18px;"
               class="font-weight-semibold text-black">
                {{ $t('Boats') }}
            </p>
            <v-btn v-if="filterPreBoats == 'onboarding'"
                   class="owner-info-btn me-3 mb-3 rounded-lg white--text text-capitalize float-right font-weight-thin"
                   @click="addBoat">
                {{ $t('Add Boats') }}
            </v-btn>
        </div>
        <v-divider></v-divider>
        <v-card id="boat-list"
                class="mt-6">
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="text-green font-weight-semibold text-base">{{ $t('Boat Listing') }}</span>
                    <!-- </div> -->
                </div>

                <div v-if="filterPreBoats == 'onboarding'"
                     class="d-flex align-center pb-5">
                    <v-select v-model="filterStatus"
                              single-line
                              dense
                              outlined
                              :items="statuses"
                              variant="underlined"
                              @change="onChangeFilters()"
                              item-text="name"
                              item-value="id"
                              hide-details
                              :placeholder="$t('Select Status')"
                              class="boat-list-search me-3"></v-select>

                    <v-select v-model="filterManager"
                              v-if="admin"
                              single-line
                              dense
                              outlined
                              :items="boatOwners"
                              variant="underlined"
                              @change="onChangeFilters('managers')"
                              item-text="first_name"
                              item-value="id"
                              hide-details
                              :placeholder="$t('Select Boat Owner')"
                              class="boat-list-search me-3"></v-select>

                    <v-text-field v-model="searchText"
                                  dense
                                  outlined
                                  prepend-inner-icon="mdi-magnify"
                                  @input="onSearch()"
                                  hide-details
                                  :placeholder="$t('Search')"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                </div>
            </v-card-text>

            <div v-if="admin"
                 class="d-flex mb-8 mx-6">
                <div style="cursor:pointer;"
                     @click="filterBoats('pre-onboarding')"
                     class="mr-6 d-flex flex-column justify-center">
                    <div class="text-blue font-weight-semibold">
                        <span class="d-flex">
                            <div v-bind:class="{ 'boat-info': pre, 'past': !pre }">
                                {{ $t('Pre Register') }}
                            </div>
                        </span>
                    </div>
                </div>
                <div class="d-flex"
                     style="height: 30px;">
                    <v-divider class="my-2"
                               :vertical="true"></v-divider>
                </div>
                <div style="cursor:pointer;"
                     @click="filterBoats('onboarding')"
                     class="ml-6 d-flex flex-column justify-center">
                    <div class="text-blue font-weight-semibold">
                        <span class="d-flex">
                            <div class="pa-2"
                                 v-bind:class="{ 'boat-info': !pre, 'past': pre }">
                                {{ $t('Registered Boats') }}
                            </div>
                        </span>
                    </div>
                </div>
            </div>

            <RejectionDialog :modal="cancelModal"
                             :notes="notes"
                             @closed="closeDialog"></RejectionDialog>

            <!-- table -->
            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="boatListTable"
                          :options.sync="options"
                          :footer-props="{ itemsPerPageOptions: [5, 10] }"
                          header-color="purple darken-2"
                          :server-items-length="totalBoatListTable"
                          :loading="loading"
                          :class="{ 'light-mode': shouldApplyClass, 'dark-mode': !shouldApplyClass }"
                          class="custom-header-color my-data-table">

                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }">

                    <div class="text-black font-size"> {{ item.id }}</div>

                </template>

                <template #[`item.owner_name`]="{ item }">
                    <div class="text-black font-size">{{ item.owner_name }}</div>
                </template>

                <template #[`item.manager_name`]="{ item }">
                    <div class="text-black font-size">{{ item.manager_name }}</div>
                </template>

                <template #[`item.boat_name`]="{ item }">
                    <div class="text-black font-size">{{ item.boat_name }}</div>
                </template>
                <template #[`item.cityName`]="{ item }">
                    <div class="text-black font-size">{{ item.cityName }}</div>
                </template>
                <template #[`item.location`]="{ item }">
                    <div class="text-black font-size">{{ item.location }}</div>
                </template>
                <template #[`item.status`]="{ item }">
                    <v-chip :color="resolveStatusVariant(item.status).variant"
                            :class="`v-chip-light-bg ${resolveStatusVariant(item.status).variant}--text font-weight-thin text-caption`">
                        {{ $t(resolveStatusVariant(item.status).name) }}
                    </v-chip>
                </template>


                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">

                       <div  v-if="item.status == '1'">
                        <template v-if="filterPreBoats == 'onboarding'"
                                  bottom>
                            <v-switch v-model="item.is_active"
                                      inset
                                      @change="activateStatus(item.is_active, item.id)"
                                      :value=true>
                            </v-switch>
                        </template>
                       </div>


                        <div v-if="role.role != 'boat_owner'">
                            <v-tooltip bottom
                                       v-if="item.status == 0">
                                <template #activator="{ on, attrs }">
                                    <v-btn icon
                                           small
                                           v-bind="attrs"
                                           color="#00B2A9"
                                           @click="updateBoatStatus('Update', item)"
                                           v-on="on">
                                        <v-icon size="18"
                                                class="text-green">
                                            {{ icons.mdiCheck }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Approve') }}</span>
                            </v-tooltip>
                        </div>


                        <!-- reject  -->
                        <div v-if="role.role != 'boat_owner'">
                            <v-tooltip bottom
                                       v-if="item.status != 2 && item.status != 3">
                                <template #activator="{ on, attrs }">
                                    <v-btn icon
                                           small
                                           color="#FF0000"
                                           v-bind="attrs"
                                           @click="updateBoatStatus('Reject', item)"
                                           v-on="on">
                                        <v-icon size="18"
                                                color="warn">
                                            {{ icons.mdiClose }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Reject') }}</span>
                            </v-tooltip>
                        </div>


                        <v-tooltip bottom
                                   v-if="item.status == 2 && item.rejection_notes != null">
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="showRejectionDialog(item.rejection_notes)"
                                       v-on="on">
                                    <v-icon size="18"
                                            class="text-green">
                                        {{ icons.mdiHelpCircle }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View Rejection Notes') }}</span>
                        </v-tooltip>

                        <v-tooltip bottom
                                   v-if="item.status == 3">
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="updateBoatStatus('Approval', item)"
                                       v-on="on">
                                    <v-icon size="18"
                                            class="text-green">
                                        {{ icons.mdiCheck }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Submit For Approval') }}</span>
                        </v-tooltip>

                        <!-- download  -->
                        <!-- <v-tooltip v-if="item.user_application != null"
                                   bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       v-if="(item.users_verification.status === 'approved' && item.users_verification.status !== 'rejected')"
                                       small
                                       v-bind="attrs"
                                       class="text-green"
                                       @click="downloadBoatApplication(item)"
                                       v-on="on">
                                    <v-icon size="18"
                                            class="text-green">
                                        {{ icons.mdiDownload }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Download</span>
                        </v-tooltip> -->

                        <!-- view -->
                        <span v-if="filterPreBoats == 'onboarding'">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon
                                           small
                                           v-bind="attrs"
                                           color="#00B2A9"
                                           @click="updateBoat(item)"
                                           v-on="on">
                                        <v-icon size="18"
                                                class="text-green">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Edit') }}</span>
                            </v-tooltip>
                        </span>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       color="#00B2A9"
                                       v-bind="attrs"
                                       @click="viewBoat(item)"
                                       v-on="on">
                                    <v-icon size="20"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>

                    </div>
                </template>
            </v-data-table>

        </v-card>
    </div>
</template>

<script>
import
{
    mdiCheck, mdiClose, mdiDeleteOutline,
    mdiDotsVertical, mdiDownload, mdiEyeOutline,
    mdiHelpCircle,
    mdiPlus, mdiTrendingUp
} from '@mdi/js';


// store module

// composition function
import useBoatsList from './useMyboatList';

import darkMode from '@core/utils/modalColor';

import router from '@/router';
import boatServices from '@/services/boat-services';
import userServices from '@/services/user-services';

import RejectionDialog from '@/views/dialogs/RejectionDialog.vue';
import store from '@/store';
export default {
    components: {
        RejectionDialog
    },
    data: () => ({
        pre: false,
        applicationList: '',
        shouldApplyClass: true,
        notes: '',
        admin: false,
        cancelModal: false,
        customItemsPerPage: [5, 10],
        role: 'admin',
        role_name: localStorage.getItem('role_name'),
        boatOwners: [],
        statuses: [
            {
                "id": '',
                "name": 'All'
            },
            {
                "id": '1',
                "name": 'Listing'
            },
            {
                "id": '0',
                "name": 'Pending Checklist'
            },
            {
                "id": '2',
                "name": 'Rejected'
            },
            {
                "id": '3',
                "name": 'Draft'
            }
        ]
    }),
    created()
    {

        store.subscribe((mutation) =>
        {
            // console.log(mutation)
            if (mutation.type === 'accounts/SET_DARK_MODE')
            {
                this.shouldApplyClass = mutation.payload;
                // return {
                //     'my-class': this.shouldApplyClass // Conditionally apply 'my-class' based on 'shouldApplyClass' value
                // };
            }
        })
    },
    mounted()
    {
        // this.getApplicationList();
        let isDark = localStorage.getItem('my-active-theme');
        if (isDark == 'dark')
        {
            this.shouldApplyClass = false;
        }
        this.role = JSON.parse(localStorage.getItem('userData'));

        this.getAllOwners();
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData.role == 'admin')
        {
            this.admin = true;
        }
    },
    // Enabe searchbar of v-data-table
    computed: {
        // filteredItems()
        // {
        //     if (this.searchText)
        //     {
        //         const query = this.searchText.toLowerCase();
        //         return this.boatListTable.filter(item =>
        //             Object.values(item).some(
        //                 value => value && value.toString().toLowerCase().includes(query)
        //             )
        //         );
        //     }
        //     return this.boatListTable;
        // }
    },

    methods: {
        filterBoats(type)
        {
            if (type == 'pre-onboarding')
            {
                this.pre = true;
            }
            else
            {
                this.pre = false
            }
            this.filterPreBoats = type;
        },
        activateStatus(status, id)
        {

            let body;
            if (status == true)
            {
                body = {
                    "id": id,  // boat id
                    "is_active": true  // true or false
                }
            }
            else
            {
                body = {
                    "id": id,  // boat id
                    "is_active": false  // true or false
                }
            }

            boatServices.updateStatus(body, this.filterPreBoats).then(resp =>
            {
                // console.log(resp);
                if (resp.statusCode == 200)
                {
                    let status = resp.data.boat.is_active == true ? 'Boat has been activated successfully' : 'Boat has been deactivated successfully';
                    this.$swal({
                        text: `${status}`,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {
                        this.options = [];
                    })
                }
                else
                {

                }
            })
        },
        closeDialog()
        {

            this.cancelModal = false
        },
        addBoat()
        {
            router.push('/myboat/add');
        },
        showRejectionDialog(item)
        {
            this.cancelModal = true;
            this.notes = item;

        },
        updateBoat(item)
        {
            router.push(`/myboat/update/${item.id}`);

        },
        viewBoat(item)
        {
            if (this.filterPreBoats == 'onboarding')
            {
                router.push(`/myboat/view/${item.id}`);
            }
            else
            {
                router.push(`/myboat/pre/${item.id}`);
            }
        },
        //     getColor (status) {
        //     if (status =='0') return '#FFF6E8'
        //     else if (status =='1') return '#D6FFEB'
        //     else return '#FFE8E8'
        //   },
        //   getText (status) {
        //     if (status =='0') return 'warning--text'
        //     else if (status =='1') return 'success--text'
        //     else return '#ff0000'
        //   },
        getAllOwners()
        {
            let data = {
                role: 'boat_owner'
            }
            userServices.getOwnerManagerList(data).then(resp =>
            {
                // console.log();
                if (resp.statusCode == 200)
                {
                    this.boatOwners = resp.data.users
                    this.boatOwners.push('All')
                    // this.boatOwners.sort((a, b) => a.first_name - b.first_name);
                    // // console.log(this.boatOwners);
                    this.boatOwners.sort(function (a, b)
                    {
                        if (a < b)
                        {
                            return -1;
                        } else if (a > b)
                        {
                            return 1;
                        } else
                        {
                            return 0;
                        }
                    });
                }
            })

        },
        downloadBoatApplication(item)
        {
            // const link2 = document.createElement('a');
            // link2.href = 'http://myboat-api.xema.site/uploads/' + item.user_application;
            // link2.setAttribute('download', item.user_application);
            // link2.setAttribute('target', '_blank');
            // document.body.appendChild(link2);
            // link2.click();
        },
        updateBoatStatus(status, item)
        {
            if (status == 'Approval')
            {
                this.options = [];
                let dict;
                let url;
                url = 'boat/' + this.filterPreBoats + '/submit-boat'
                dict = {
                    status: 0,
                    id: item.id,
                    submitting_notes: ""
                }

                boatServices.updateApplicationStatus(dict, url).then(resp =>
                {
                    this.options = [];
                    // console.log(resp);
                    if (resp.error)
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Something went wrong!',
                        })
                    }
                    else
                    {
                        this.$swal({
                            // title: `Status Approved`,
                            text: 'Boat has been send for approval',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            icon: 'success',
                        }).then(result =>
                        {

                            // refresh here
                        })
                    }
                });
            }

            else if (status == 'Reject')
            {
                let rejection_notes = '';
                
                this.$swal({
                    title: status,
                    // text: headTxt,
                    // text: "Do you want to " + status + "?",
                    type: "warning",
                    input: 'textarea',
                    inputAttributes: {
                        autocapitalize: 'off',
                        required: 'true'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Reject',
                    confirmButtonColor: darkMode.getTextColor(),
                    customClass: darkMode.getBackgroundColor(),
                    inputValidator: (value) =>
                    {
                        return new Promise((resolve) =>
                        {
                            if (value)
                            {
                                resolve();
                            } else
                            {
                                resolve('Please Enter Rejection Note');
                            }
                        });
                    },
                }).then((result) =>
                {
                    if (result.isConfirmed)
                    {
                        this.options = [];
                        let dict;
                        let url;

                        if (this.filterPreBoats == 'pre-onboarding')
                        {
                            url = 'boat/' + this.filterPreBoats + '/reject';
                        }
                        else
                        {
                            url = 'boat/' + this.filterPreBoats + '/reject-boat';
                        }
                        dict = {
                            status: 2,
                            id: item.id,
                            rejection_notes: result.value
                        }
                        boatServices.updateApplicationStatus(dict, url).then(resp =>
                        {
                            this.options = [];
                            // console.log(resp);
                            if (resp.error)
                            {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    confirmButtonColor: darkMode.getTextColor(),
                                    customClass: darkMode.getBackgroundColor(),
                                    text: 'Something went wrong!',
                                })
                            }
                            else
                            {
                                // this.$swal({
                                //     title: `Boat has been rejected successfully`,
                                //     confirmButtonColor: darkMode.getTextColor(),
                                //     customClass: darkMode.getBackgroundColor(),
                                //     icon: 'success',
                                // }).then(result =>
                                // {

                                //     // refresh here
                                // })
                            }
                        });
                    }
                })
            }
            else
            {
                if (status == 'Update' && this.filterPreBoats == 'onboarding')
                {
                    if (item?.boat_pricing?.commission == null)
                    {
                        this.$swal({
                            icon: 'error',
                            title: '',
                            confirmButtonColor: darkMode.getTextColor(),
                            customClass: darkMode.getBackgroundColor(),
                            text: 'Please add MyBoat commission to complete the Boat registration',
                        })
                        return;
                    }
                }
                let acceptance_notes = '';
                this.$swal({
                    title: 'Approve',
                    // text: headTxt,
                    text: "Do you want to Approve?",
                    type: "warning",
                    // input: 'textarea',
                    // inputAttributes: {
                    //     autocapitalize: 'off',
                    //     required: 'true'
                    // },
                    showCancelButton: true,
                    confirmButtonText: 'Approve',
                    confirmButtonColor: darkMode.getTextColor(),
                    customClass: darkMode.getBackgroundColor(),
                    inputValidator: (value) =>
                    {
                        return new Promise((resolve) =>
                        {
                            if (value)
                            {
                                acceptance_notes = value;
                                resolve();
                            } else
                            {
                                resolve('Please Enter Acceptance Note');
                            }
                        });
                    },
                }).then((result) =>
                {

                    if (result.isConfirmed)
                    {
                        this.options = [];
                        let dict;
                        let url;
                        if (this.filterPreBoats == 'onboarding')
                        {
                            if (status == 'Update')
                            {
                                url = 'boat/' + this.filterPreBoats + '/accept-boat'
                                // console.log(result);
                                dict = {
                                    status: 1,
                                    id: item.id,
                                    acceptance_notes: acceptance_notes
                                }
                            }
                        }
                        else
                        {
                            if (status == 'Update')
                            {
                                url = 'boat/' + this.filterPreBoats + '/approve'
                                dict = {
                                    status: 1,
                                    id: item.id,
                                    acceptance_notes: acceptance_notes
                                }
                            }
                        }
                        boatServices.updateApplicationStatus(dict, url).then(resp =>
                        {
                            this.options = [];
                            // console.log(resp);
                            if (resp.error)
                            {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    confirmButtonColor: darkMode.getTextColor(),
                                    customClass: darkMode.getBackgroundColor(),
                                    text: 'Please add MyBoat commission to complete the Boat registration',
                                })
                            }
                            else
                            {
                                this.$swal({
                                    // title: `Status Approved`,
                                    text: 'Boat has been approved',
                                    confirmButtonColor: darkMode.getTextColor(),
                                    customClass: darkMode.getBackgroundColor(),
                                    icon: 'success',
                                }).then(result =>
                                {

                                    // refresh here
                                })
                            }
                        });
                    }
                })
            }

        },
    },
    setup()
    {
        const {
            boatListTable,
            tableColumns,
            options,
            totalBoatListTable,
            loading,
            filterManager,
            searchText,
            filterStatus,
            selectedTableData,
            resolveStatusVariant,
            onChangeFilters,
            onSearch,
            filterPreBoats
        } = useBoatsList()

        const actionOptions = [
            // { title: 'Download', icon: mdiDownloadOutline },
            { title: 'Approve', icon: mdiDeleteOutline },
            { title: 'Reject', icon: mdiDeleteOutline },
        ]

        return {
            tableColumns,
            options,
            totalBoatListTable,
            boatListTable,
            loading,
            filterManager,
            searchText,
            filterStatus,
            selectedTableData,
            actionOptions,
            resolveStatusVariant,
            onChangeFilters,
            onSearch,
            filterPreBoats,

            icons: {
                mdiTrendingUp,
                mdiPlus,
                mdiDownload,
                mdiDeleteOutline,
                mdiDotsVertical,
                mdiEyeOutline, mdiCheck, mdiClose, mdiHelpCircle
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#boat-list {
    .boat-list-actions {
        max-width: 7.81rem;
    }

    .boat-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }


    .boat-list-status {
        max-width: 11.3rem;
    }

    .text-no-wrap {
        border-left: 1px solid #ccc;
    }

    .font-size {
        font-size: 14px;
        font-weight: 400;
    }

    .my-data-table ::v-deep .v-data-table-header th {
        font-weight: bold;
        font-size: 14px;
        height: 40px;
    }
}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}


.boat-info {
    background-color: #00B2A9 !important;
    border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    font-size: 14px;
}

.past {
    background-color: #e9e9e9 !important;
    // border: 3px solid;
    border-width: 1px;
    padding: 6px;
    border-radius: 6px;
    color: #00B2A9;
    font-size: 14px;
}

.custom-header-color .v-data-table-header {
    color: rgb(160, 9, 9);
}



// ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
// .v-data-table>.v-data-table__wrapper>table>thead>tr>td,
// .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
//     font-size: 0.875rem;
//     height: 3.125rem;
//     border-left: 1px solid #efeded !important;
// }

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

// ::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgba(94, 86, 105, 0.14);
//     border-right: 1px solid #efeded;
// }
</style>
