
export const getBackgroundColor = () =>
{

    let isDark = localStorage.getItem('my-active-theme');
    let customClass = '';
    if (isDark == 'dark')
    {
        return customClass = "swal-background";
    }
    else{
        return customClass;
    }
}

export const getTextColor = () =>
{

    let isDark = localStorage.getItem('my-active-theme');
    let textColor = '';
    if (isDark == 'dark')
    {
        return textColor = "#312d4b";
    }
    else{
        return textColor = "#00b2a9";
    }
}

export default {
    getBackgroundColor,
    getTextColor
}