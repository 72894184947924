import { ref, watch } from '@vue/composition-api';

import boatServices from '@/services/boat-services';

import store from '@/store';
import { useUtils } from '@core/libs/i18n';

import debounce from "lodash/debounce";

const { t } = useUtils()

export default function useBoatsList()
{
    const boatListTable = ref([])
    const boatListTableFilter = ref([])


    // Table Handlers
    const tableColumns = [
        {
            text: 'ID',
            value: 'id',
            sortable: false,
        },
        // { text: 'Trending', value: 'trending', sortable: false },
        // { text: 'NAME', value: 'client' },
        { text: t('Boat Name'), value: 'boat_name', sortable: false, align: 'center' },
        { text: t('Owner Name'), value: 'owner_name', sortable: false, align: 'center' },
        // { text: t('Manager Name'), value: 'manager_name', sortable: false, align: 'center' },
        { text: t('City'), value: 'cityName', sortable: false, align: 'center' },
        { text: t('Location'), value: 'location', sortable: false, align: 'center' },
        { text: t('Status'), value: 'status', sortable: false, align: 'center' },
        {
            text: t('Actions'),
            value: 'actions',
            align: 'center',
            sortable: false,
        },
    ]

    const filterManager = ref('')
    const searchText = ref('')
    const filterStatus = ref('')
    const updateStatus = ref('')

    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalBoatListTable = ref(0)
    const loading = ref(false)
    const statusFilter = ref(null)
    const filterPreBoats = ref('onboarding')
    const selectedTableData = ref([])
    const fetchBoats = (slug) =>
    {
        store.set('accounts/loader', true);

        boatServices.getApplicationList(options, searchText.value, filterManager.value, filterStatus.value, slug).then(resp =>
        {
            if (resp.data.statusCode == 200)
            {
                store.set('accounts/loader', false);
                let data = resp?.data?.data?.boats;

                data.forEach(element =>
                {
                    if (filterPreBoats.value == 'pre-onboarding')
                    {
                        console.log('dsdsdsdsdsd')
                        element['boat_documents'] = element.pre_boat_documents;
                        element['boat_pictures'] = element.pre_boat_pictures;
                        element['boat_pricing'] = element.pre_boat_pricing;
                        element['boats_condition'] = element.pre_boats_condition;
                        element['boats_owner'] = element.pre_boats_owner;
                    }

                    if (element.boats_owner)
                    {
                        element.owner_name = element.boats_owner.owner_name;
                    }
                    if (element.boat_admin)
                    {
                        element.manager_name = element.boat_admin.first_name;
                    }
                    if (element?.city)
                    {
                        element.cityName = element.city.name;
                    }
                });
                totalBoatListTable.value = resp.data.data?.pagination.count

                console.log(data);
                boatListTable.value = [];
                boatListTable.value = data;
                boatListTableFilter.value = data;
                loading.value = false;
            }
        })
    }

    const onSearch = debounce(() =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchBoats(filterPreBoats.value);
    }, 500);

    const onChangeFilters = () =>
    {
        options.value.page = 1;
        loading.value = true
        selectedTableData.value = []
        fetchBoats(filterPreBoats.value);
    };

    watch([options, filterPreBoats], ([newoptions, newfilter], [prevoptions, prevFilter]) =>
    {

        loading.value = true
        selectedTableData.value = []
        fetchBoats(newfilter)
    });

    // fetchBoats();

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveStatusVariant = status =>
    {
        if (status === '1') return { variant: 'success', name: 'Listing' }
        // if (status === null) return { variant: 'warning', name: 'Under Process' }
        if (status === '0') return { variant: 'warning', name: 'Pending Checklist' }
        if (status === '2') return { variant: 'error', name: 'Rejected' }
        if (status === '3') return { variant: 'error', name: 'Draft' }
        // if (status === 'pending_boat_inspection') return { variant: 'info', name: 'Pending Inspection' }

        return { variant: 'secondary', name: 'Not Available' }
    }

    const resolveClientAvatarVariant = status =>
    {
        if (status === 'Partial Payment') return 'primary'
        if (status === 'Paid') return 'error'
        if (status === 'Downloaded') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'

        return 'primary'
    }

    // fetchBoats();

    return {
        tableColumns,
        options,
        updateStatus,
        boatListTable,
        statusFilter,
        totalBoatListTable,
        loading,
        filterManager,
        searchText,
        filterStatus,
        selectedTableData,
        fetchBoats,
        // resolveBoatStatusVariantAndIcon,
        resolveClientAvatarVariant,
        resolveStatusVariant,
        onChangeFilters,
        onSearch,
        filterPreBoats
    }
}
