<template>
    <div class="text-center">
        <v-dialog v-model="modal"
                  persistent>
            <v-card class="security v-dialog-rejection">
                <div class="float-right mr-5 mt-3 px-2 py-1 rounded-circle"
                     style="background-color:#FAFAFA;
                cursor: pointer;"
                     @click="closeDialog">
                    <v-icon color="#00B2A9"
                            size="15"
                            class="mt-n1 mx-auto">mdi-close-circle-outline</v-icon>
                </div>
                <v-card-title>
                    <span class="font-weight-semibold text-black mx-1"
                          style="font-size: 17px;">
                        {{ $t('Rejection Note') }}
                    </span>
                    <span class="en-close">
                        <v-icon>

                        </v-icon>
                    </span>
                </v-card-title>


                <v-card-text style="width: 78%;"
                             class="pa-0 mx-5">
                    <!-- <v-divider></v-divider> -->
                </v-card-text>





                <v-card-text class="pa-5">
                    <v-textarea solo
                                outlined
                                :disabled="true"
                                v-model="notes"
                                class="mt-2"
                                :label="$t('Rejection Note...')">
                    </v-textarea>
                    <!-- <v-row>
                        <v-col lg="12">
                            <v-btn @click="dialogClose" class="text-capitalize font-weight-regular"
                            :disabled="true"
                                   style="display: block; width: 100%; background-color:#0C2752; color:white">
                                Cancel Booking
                            </v-btn>
                        </v-col>
                    </v-row> -->

                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    props: {
        modal: {
            default: false,
            type: Boolean
        },
        notes: {
            default: '',
            type: String
        },
    },
    data: () => ({
        dialog: true,
        rejection_notes: null
    }),

    methods: {
        closeDialog()
        {
            this.$emit('closed');
            return this.modal = !this.modal;
        }
    },
    mounted()
    {
        window.scrollTo(0, 0);
    },
}
</script>

<style lang="scss" scoped>
.card-action {
    margin-left: 9px;
    background: #E2F1FD;
    width: 29px;
    height: 27px;
    border-radius: 50%;


}

.v-dialog {
    box-shadow: none !important;
}

.switch-btn {
    margin-bottom: -21px;
}

.v-dialog-logout {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}

.en-close {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.action-image {
    height: 35px;
    width: 35px;
    margin: 5px;
    // top: 6px;
}

.v-dialog-rejection {
    min-width: 250px !important;
    max-width: 500px !important;
    margin: 0 auto;
}

::v-deep .v-dialog {
    /* box-shadow: 0 18px 42px -6px rgba(94, 86, 105, 0.18); */
    box-shadow: none !important;
}

.circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #f44336;
    /* Replace with your desired color */
}</style>